import React, { useEffect, useState } from 'react';
import { BigNumber, ethers } from 'ethers';
import CancelModal from './CancelModal';
import AcceptModal from './AcceptModal';
import ConfirmModal from './ConfirmModal';
import JudgeModal from './JudgeModal';
import { toast } from 'react-toastify';
import { TradeItem, TradeListStyled, TradeContainer, } from '../styles/GlobalModalStyles';
import { listenOffersFromDB, updateOfferOnDB } from '../hooks/firebaseConfig';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import 'react-toastify/dist/ReactToastify.css';
import { parseBigNumberToString } from '../utils/formatter';

interface Limits {
    collateralPercent: number;
    feePercent: number;
}

interface Counts {
    tradeCount: BigNumber;
    buyCount: BigNumber;
    sellCount: BigNumber;
};

interface User {
    user: string;
    username: string;
    limits: Limits;
    counts: Counts;
}

interface Trade {
    user1: User;
    user2: User;
    tradeId: BigNumber;
    sellTrade: boolean;
    amount: BigNumber;
    price: BigNumber;
    fiatCurrency: string;
    acceptedTimestamp: BigNumber;
}

interface TradeListProps {
    trades: Trade[];
    account: string | null;
    isJudge: boolean;
    isOwner: boolean;
    userBalance: BigNumber;
    user: User;
    onModalStateChange: (isOpen: boolean) => void;
}

interface Offer {
    [key: string]: boolean;
}

const TradeList: React.FC<TradeListProps> = ({ trades, account, isJudge, isOwner, userBalance, user, onModalStateChange }) => {
    const [cancelModalOpen, setCancelModalOpen] = useState(false);
    const [acceptModalOpen, setAcceptModalOpen] = useState(false);
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [judgeModalOpen, setJudgeModalOpen] = useState(false);
    const [selectedTrade, setSelectedTrade] = useState<Trade | null>(null);
    const [debugMessage, setDebugMessage] = useState<string | null>(null);
    const [offers, setOffers] = useState<Offer | null>(null);

    useEffect(() => {
        listenOffersFromDB((data: Offer | null) => {
            if (data) {
                setOffers(data);
            }
        });
    }, []);

    const handleTradeClick = (trade: Trade) => {

        const collateralAmount = trade.amount.mul(user.limits.collateralPercent).div(10000);
        const feeAmount = trade.amount.mul(user.limits.feePercent).div(10000);

        const minToAcceptTrade = trade.sellTrade ? feeAmount.add(collateralAmount) : trade.amount.add(feeAmount).add(collateralAmount);

        const tradeId = trade.tradeId.toString();

        setSelectedTrade(trade);

        if (isOwner) {
            return;
        } else if (isJudge) {
            if (trade.user2.user !== ethers.constants.AddressZero) {
                setJudgeModalOpen(true);
                onModalStateChange(true);
            } else {
                toast.warn("Esta oferta ainda não foi aceita!");
            }
        } else if (trade.sellTrade) {
            if (account === trade.user1.user) {
                if (trade.user2.user !== ethers.constants.AddressZero) {
                    setConfirmModalOpen(true);
                    onModalStateChange(true);
                } else {
                    if (offers?.[tradeId]) {
                        updateOfferOnDB(tradeId, false);
                        setCancelModalOpen(true);
                        onModalStateChange(true);
                    } else {
                        toast.warn("Esta oferta está ocupada!");
                    }
                }
            } else if (userBalance.gte(minToAcceptTrade)) {
                if (trade.user2.user === ethers.constants.AddressZero) {
                    if (offers?.[tradeId]) {
                        updateOfferOnDB(tradeId, false);
                        setAcceptModalOpen(true);
                        onModalStateChange(true);
                    } else {
                        toast.warn("Esta oferta está ocupada!");
                    }
                } else {
                    toast.warn("Esta oferta foi aceita!");
                }
            }
            else if (trade.user2.user === ethers.constants.AddressZero) {
                toast.warn("Saldo insuficiente para aceitar esta oferta!");
            } else {
                toast.warn("Esta oferta já foi aceita!");
            }
        } else {
            if (account === trade.user1.user) {
                if (trade.user2.user === ethers.constants.AddressZero) {
                    if (offers?.[tradeId]) {
                        updateOfferOnDB(tradeId, false);
                        setCancelModalOpen(true);
                        onModalStateChange(true);
                    } else {
                        toast.warn("Esta oferta está ocupada!");
                    }
                } else {
                    toast.warn("Esta oferta foi aceita!");
                }
            } else if (userBalance.gte(minToAcceptTrade)) {
                if (trade.user2.user === ethers.constants.AddressZero) {
                    if (offers?.[tradeId]) {
                        updateOfferOnDB(tradeId, false);
                        setAcceptModalOpen(true);
                        onModalStateChange(true);
                    } else {
                        toast.warn("Esta oferta está ocupada!");
                    }
                } else {
                    setConfirmModalOpen(true);
                    onModalStateChange(true);
                }
            }
            else if (trade.user2.user === ethers.constants.AddressZero) {
                toast.warn("Saldo insuficiente para aceitar esta oferta!");
            } else {
                toast.warn("Esta oferta já foi aceita!");
            }
        }
    };

    const renderTrade = (trade: Trade) => {
        const isOwnerUser = account === trade.user1.user;
        const isAccepterUser = account === trade.user2.user;
        const isAccepted = trade.user2.user !== ethers.constants.AddressZero;

        let backgroundColor = trade.sellTrade ? '#D22D2D' : '#0FB10F';

        let isBlinking = "no";

        let disableHover = "no";

        const collateralAmount = trade.amount.mul(user.limits.collateralPercent).div(10000);
        const feeAmount = trade.amount.mul(user.limits.feePercent).div(10000);

        const minToAcceptTrade = trade.sellTrade ? feeAmount.add(collateralAmount) : trade.amount.add(feeAmount).add(collateralAmount);

        const buyAndSellCount = parseFloat(trade.user1.counts.buyCount.add(trade.user1.counts.sellCount).toString());

        const score = buyAndSellCount > 0 ? (buyAndSellCount / parseFloat(trade.user1.counts.tradeCount.toString())) * 100 : 100;

        let offerStatus = offers?.[trade.tradeId.toString()] ?? true;

        if (isOwnerUser && isAccepted) {
            disableHover = "yes";
            backgroundColor = "#3C99DC";
            if (trade.sellTrade) {
                isBlinking = "yes";
            } else {
                isBlinking = "no";
                backgroundColor = "gray";
            }
        } else if (isAccepterUser && isAccepted) {
            disableHover = "yes";
            if (trade.sellTrade) {
                backgroundColor = "gray";
            } else {
                backgroundColor = "gray";
                isBlinking = "yes";
            }
        } else if (isAccepted || !offerStatus) {
            disableHover = "yes";
            backgroundColor = "gray";
        } else if (isOwnerUser) {
            backgroundColor = "#3C99DC";
        } else if (!isOwner && !isJudge && !userBalance.gte(minToAcceptTrade)) {
            disableHover = "yes";
            backgroundColor = "gray";
        }

        return (
            <TradeItem
                key={trade.tradeId.toString()}
                $variation={!trade.sellTrade ? "inverted" : "normal"}
                color={backgroundColor}
                $blinking={isBlinking}
                $hover={disableHover}
                onClick={() => handleTradeClick(trade)}
            >
                <p>{isOwnerUser || isJudge || isAccepterUser ? `ID ${trade.tradeId}` : `${parseFloat(score.toString()).toFixed(0)}%`}</p>
                <p>{parseFloat(parseBigNumberToString(trade.amount))} USDC</p>
                <p>{parseFloat(ethers.utils.formatUnits(trade.price, 2)).toFixed(2)} {trade.fiatCurrency}</p>
                <p>{(parseFloat(ethers.utils.formatUnits(trade.amount, 6)) * parseFloat(ethers.utils.formatUnits(trade.price, 2))).toFixed(2)} {trade.fiatCurrency}</p>
            </TradeItem>
        );
    };

    const sortTrades = (revert = false) => {
        return trades.sort((a, b) => {
            const priceA = ethers.BigNumber.from(a.price);
            const priceB = ethers.BigNumber.from(b.price);

            const factor = revert ? -1 : 1;

            if (priceA.lt(priceB)) return -1 * factor;
            if (priceA.gt(priceB)) return 1 * factor;

            return 0;
        });
    };

    return (
        <TradeContainer>
            {debugMessage && <p style={{ color: 'blue' }}>{debugMessage}</p>}
            <TradeListStyled>
                <TransitionGroup>
                    {sortTrades().filter(trade => trade.sellTrade).map((trade) => (
                        <CSSTransition
                            key={trade.tradeId.toString()}
                            timeout={500}
                            classNames="fade"
                            onExit={(node) => node.classList.add('fade-out')}
                        >
                            {renderTrade(trade)}
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </TradeListStyled>
            <TradeListStyled>
                <TransitionGroup>
                    {sortTrades(true).filter(trade => !trade.sellTrade).map((trade) => (
                        <CSSTransition
                            key={trade.tradeId.toString()}
                            timeout={500}
                            classNames="fade"
                            onExit={(node) => node.classList.add('fade-out')}
                        >
                            {renderTrade(trade)}
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </TradeListStyled>
            {cancelModalOpen && selectedTrade && (
                <CancelModal trade={selectedTrade} onClose={() => { setCancelModalOpen(false); onModalStateChange(false) }} />
            )}
            {acceptModalOpen && selectedTrade && (
                <AcceptModal trade={selectedTrade} user={user} onClose={() => { setAcceptModalOpen(false); onModalStateChange(false) }} />
            )}
            {confirmModalOpen && selectedTrade && (
                <ConfirmModal trade={selectedTrade} onClose={() => { setConfirmModalOpen(false); onModalStateChange(false) }} />
            )}
            {judgeModalOpen && selectedTrade && (
                <JudgeModal trade={selectedTrade} onClose={() => { setJudgeModalOpen(false); onModalStateChange(false) }} />
            )}
        </TradeContainer>
    );
};

export default TradeList;
